import React, { useEffect, useState } from 'react';
import UploadHistory from './UploadHistory';

function UploadSuccess(props){

    const session = props?.session;
    const viewType = session?.env?.viewport?.viewType;
    const prevParticipants = session?.upload?.data?.prevNumIndividuals;
    const totalParticipants = session?.upload?.data?.errorCountData?.total_participants;
    const date = new Date();
    const currentDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
    const numInvalid = calculateTotalCount(session?.upload?.data?.errorCountData);

    function calculateTotalCount(errorCountData) {
        return Object.keys(errorCountData).reduce((acc, key) => {
            // Skip total_participants in the accumulation
            if ((key !== 'total_participants') && (key !== 'file_name') && (key !== 'city') && (key !== 'state')) {
                acc += errorCountData[key];
            }
            return acc;
        }, 0); 
    }

    function calculateAverageAge(participants) {
        // Get current date
        const currentDate = new Date();
        
        // Filter out invalid dates and calculate age for each participant
        const ages = participants
          .map(participant => {
            const dob = new Date(participant.dob);
            if (!isNaN(dob.getTime())) {
              // Calculate age
              let age = currentDate.getFullYear() - dob.getFullYear();
              const m = currentDate.getMonth() - dob.getMonth();
              if (m < 0 || (m === 0 && currentDate.getDate() < dob.getDate())) {
                age--;
              }
              return age;
            }
            return null;
          })
          .filter(age => age !== null); // Remove null values which are invalid dates
      
        // Calculate average age
        const totalAges = ages.reduce((acc, age) => acc + age, 0);
        const averageAge = totalAges / ages.length;
      
        // Round to one decimal point
        const roundedAverageAge = parseFloat(averageAge.toFixed(1));

        if(roundedAverageAge < 0){
            roundedAverageAge = 0;    
        }
        
        return roundedAverageAge;
    }
       
    const [detailsSelected, setDetailsSelected] = useState(true);

    function printOverlayContent() {
        return (
                <div className="successInfoContainer f g fC dP tO gR2 bR">
                    <div className="gR1 cC gCW greenText successMessage bold">{viewType === "mobile" ? "File Successfully Uploaded!" : "Your file has been successfully uploaded!"} </div>
                    <div className="cC gR2 g statsDisplay f">
                        <div className='gR1 g f bR statRow'>
                            <div className='gC1 cL bold'><div>Total number of uploaded participants:</div></div> 
                            <div className='gC2 cC'><div>{totalParticipants?.toLocaleString()}</div></div> 
                        </div>
                        <div className='gR2 g f bR statRow'>
                            <div className='gC1 cL bold'><div>Date of Upload:</div> </div>
                            <div className='gC2 cC'><div>{currentDate}</div></div>
                        </div> 
                        <div className='gR3 g f bR statRow'>
                            <div className='gC1 bold cL'><div>Number of potential invalid entries:</div></div> 
                            <div className='gC2 cC'><div>{numInvalid?.toLocaleString()}</div></div>               
                        </div>
                        <div className='gR4 g f bR statRow'>
                            <div className='gC1 bold cL'><div>Average age of uploaded participants:</div></div> 
                            <div className='gC2 cC'><div>{calculateAverageAge(session?.edit?.data?.importedList?.list)}</div></div> 
                        </div>
                        <div className='gR5 g f bR statRow'>
                            <div className='gC1 bold cL'><div>Number of previous participants: </div></div>
                            <div className='gC2 cC'><div>{prevParticipants?.toLocaleString()}</div></div>
                        </div> 
                        
                    </div>
                </div>         
        );
    }

    return (
        <>
            <div className="overlayBoard g f fC oH dP">
                <div className='successContainer g'>
                    <div className='gR1 g successTabs'>
                        <div className='gC1'></div>
                        <div className={`gC2 successTab bR ${detailsSelected ? 'successTabSelected' : ''}`} onClick={() => setDetailsSelected(true)} >File Details</div>
                        <div className={`gC3 successTab bR ${detailsSelected ? '' : 'successTabSelected'}`} onClick={() => {setDetailsSelected(false); session?.upload?.functions?.getHistory(session);}}>Past Uploads</div>
                    </div>
                    
                    {/* {printOverlayContent()} */}
                    { detailsSelected ?               
                        printOverlayContent()
                        :
                        <UploadHistory
                            overlay={'uploadSuccess'}
                            session={session}
                        />
                    }
                </div>
               
            </div>  
        </>
    );
};

export default UploadSuccess;