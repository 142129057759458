import React, { useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

function SFTPConfirmation(props){

    console.log(props?.file);
    const session = props?.session;

    const viewType = session?.env?.viewport?.viewType;

    const [loadingStatus, setLoadingStatus] = useState(false);

    const fileRows = session?.edit?.data?.importedList.list;
    const errorCountData = session?.upload?.data?.errorCountData;

        
    function printOverlayContent() {
        return (
            <div className='cC f dP g dG' style={{maxWidth:'750px'}}>
            {!loadingStatus ?
                <>
                    <div className='l dP'>
                        Your file will be sent to ABL servers through SFTP. Please confirm that you are uploading the following file:
                    </div>

                    <div className='sftpButtons g cC f'>
                        
                        <div className='btnWIcon cC bold saveUploadBtn dP bR gC2' onClick={() => {uploadSFTP(props?.file); setLoadingStatus(true);}}>Confirm Upload</div>

                        <div className='btnWIcon cC bold saveUploadBtn dP bR gC4' onClick={() => {props?.setter(false);}}> Close</div>

                    </div> 
                </>
                
            :
                <div className="cC f dP bR" style={{ minWidth: viewType === "mobile" ? 'auto' : '750px', minHeight: '300px' }}>
                    <CircularProgress disableShrink color="success" variant="indeterminate" fontSize="large"/>
                </div>
            }
            </div>                                              
        );                                                  
    }           
            
    function uploadSFTP(file) {
        console.log("file:", file);
        const formData = new FormData();
        formData.append("accountID", session?.handler?.data?.currentAccountID);
        formData.append("file", file);
    
        fetch("individual/addSFTP", {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(resData => {
            setLoadingStatus(false);
            if (resData.status === 200) {
                session?.env?.setOverlay("uploadSuccess");
            } else {
                session?.env?.setOverlay("uploadFailure");
            }
        })
        .catch(error => {
            setLoadingStatus(false);
            console.error('Upload error:', error);
            session?.env?.setOverlay("uploadFailure");
        });
    }

    return (
        <>
            <div className="overlayBoard g f fC oH dP">
                <div className='g'>
                    {printOverlayContent()}
                </div>
            </div>  
        </>
    );
};

export default SFTPConfirmation;