import React from 'react';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';

import '../CSS/Upload.css';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WarningIcon from '@mui/icons-material/Warning';

function Dropzone({onDrop, type}) {
  const [invalidFile, setInvalidFile] = useState(false);
  
  let {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop: 
      acceptedFiles => {
        const allowedTypes = [
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"
        ]
        const updatedFiles = acceptedFiles.filter(file => 
          allowedTypes.includes(file.type)
        ).map(file => 
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        );
        if (updatedFiles.length > 0) {
          onDrop(updatedFiles);
          setInvalidFile(false);
        } else {
          //console.warn("Invalid file type. Only Excel files are allowed.");
          setInvalidFile(true);
        }
      },
      // accept: [
      //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      //   "application/vnd.ms-excel",
      //   "text/csv"
      // ]
  });

  return (
    <div className='f uploadZone oH' style={{ backgroundColor: isDragActive ? '#e0f2f1' : null, borderColor: isDragActive ? '#26a69a' : null, color: isDragActive ? '#26a69a' : null}}>
      <div className='cC f oA'{...getRootProps()}>
        <input {...getInputProps()} />
        {
          <div>
            <div className="f cC oA gR1 g">
                {type === 'sftp' ?
                  <div className='warningBox bR' style={{ backgroundColor: isDragActive ? '#e0f2f1' : null, borderColor: isDragActive ? '#26a69a' : null, color: isDragActive ? '#26a69a' : null}}>
                        <div className='cC'>
                          <WarningAmberIcon fontSize='large' sx={{ color:'gold'}}/>
                          Upload will use SFTP to transfer your file
                        </div>
                        <div style={{fontSize:'16px'}}>Please ensure that your file contains ALL of your individuals</div>
                  </div>
                  :
                  <div className='warningBox bR' style={{ backgroundColor: isDragActive ? '#e0f2f1' : null, borderColor: isDragActive ? '#26a69a' : null, color: isDragActive ? '#26a69a' : null}}>
                        <div className='cC'>
                          <WarningAmberIcon fontSize='large' sx={{ color:'gold'}}/>
                          Upload will replace all existing entries
                        </div>
                        <div style={{fontSize:'16px'}}>Please ensure that your file contains ALL of your individuals</div>
                  </div>
                }
                
                {isDragActive ?
                    <p className='gR2 grayText' style={{fontSize:'20px'}}>Drop The Files Here ...</p> :
                    <p className='gR2 grayText' style={{fontSize:'20px'}}>Drag & Drop Files Here <br/> or Click to Select Files <br/> <FileUploadIcon/></p>
                } 
                {invalidFile && 
                 <div className='errorText'>Invalid File Type. Please use our File Template or upload a valid Excel file</div>    
                }
            </div>
          </div>
        }
      </div>
    </div>

  );
}

export default Dropzone;
