import React, { useEffect, useState } from 'react';

function Initialization(props){
    const [loadHandler, setLoadHandler] = useState({
        policyTrackline : false,
        policyData : false,
        notificationData : false,
        reportingData : false,
        allDeceased : false,
    });

    function formatNotifications(data) {
        if (!data) {
            return false;
        }

        const sortByTimestamp = (a, b) => new Date(b[1].timeStamp) - new Date(a[1].timeStamp);
        const isAllowedActivityType = (activityType) => ["Message", "Status", "Bid"].includes(activityType);

        const sortedNotifications = Object.entries(data).sort(sortByTimestamp);
        const processedRelatedIDs = { Message: {}, Status: {}, Bid: {} };

        const processNotification = (result, [key, notification]) => {
            const { relatedID, activityType } = notification;
            const processedNotification = processedRelatedIDs[activityType]?.[relatedID];

            if (isAllowedActivityType(activityType) && (!processedNotification || (processedNotification.counter++ >= 0))) {
                processedNotification || (processedRelatedIDs[activityType][relatedID] = { counter: 0 });
                result.push([key, notification]);
            }

            notification.skippedSteps = processedNotification?.counter || 0;
            return result;
        };

        const filteredNotifications = sortedNotifications.reduce(processNotification, []);

        return filteredNotifications;
    }

    function loadNotificationData(session){
        const paramVals = {
            accountID : session?.handler?.data?.currentAccountID
        }

        setLoadHandler(prevState => ({
            ...prevState,
            notificationData: "pending"
        }));

        session?.env?.functions?.buildFetchRequest("pullNotifications", paramVals)
        .then(response => response.json())
        .then(resData => {
            if(resData.status === 200){
                setLoadHandler(prevState => ({
                    ...prevState,
                    notificationData: true
                }));
                
                const notifications = formatNotifications(resData?.notificationData);
                if(notifications){
                    session?.notifications?.functions?.loadNotifications(notifications);
                }

            }else{
                setLoadHandler(prevState => ({
                    ...prevState,
                    notificationData: false
                }));
            }
        });
    }

    function loadReportingData(session, accID){
        const paramVals = {
            accountID: accID
        }
        setLoadHandler(prevState => ({
            ...prevState,
            reportingData: "pending"
        }));

        session?.env?.functions?.buildFetchRequest("user/reports", paramVals, null, null, "GET")
        .then(response => response.json())
        .then(resData => {
            if(resData.status === 200){
                // session?.reporting?.setData("allReports", null, resData.reports);
                session?.reporting?.setData(`${accID}`, 'allReports', resData.reports);
                setLoadHandler(prevState => ({
                    ...prevState,
                    reportingData: true
                }));
            }else{
                setLoadHandler(prevState => ({
                    ...prevState,
                    reportingData: false
                }));
            }
        });
    }

    function loadDeceased(session, accID){
        const paramVals = {
            accountID: accID
        };

        setLoadHandler(prevState => ({
            ...prevState,
            allDeceased: "pending"
        }));

        session?.env?.functions?.buildFetchRequest("deceased/getMany", paramVals)
        .then(response => response.json())
        .then(resData => {
            if(resData.status === 200){
                const deceasedIndividuals = resData.deceased;
                const deceasedData = session?.individual?.functions?.calculateTermData(deceasedIndividuals);
                const existingGroups = [];
    
                resData.deceased.forEach((individual) => {
                    let group = individual?.internal_group; 
                    const existingGroupIndex = existingGroups.findIndex((obj) => obj?.name === group);
    
                    if (group) {
                        if (existingGroupIndex === -1) {
                            existingGroups.push({ name: group, count: 1 });
                        } else {
                            existingGroups[existingGroupIndex].count++;
                        }
                    }

                    session?.individual?.functions?.updateAttributeData(individual, "deceased", session);
                });
                
                session?.individual?.setData(`${accID}.deathTrac.deceased`, deceasedIndividuals);
                session?.individual?.setData(`${accID}.groups`, existingGroups);
                // session?.reporting?.setData("deceased", null, deceasedData);
                // session?.reporting?.setData("deceased", "termDeaths", deceasedData);
                session?.reporting?.setData(`${accID}`, 'deceased', deceasedData);
                session?.reporting?.setData(`${accID}.deceased`, "termDeaths", deceasedData);

                setLoadHandler(prevState => ({
                    ...prevState,
                    allDeceased: true
                }));
            } else {
                setLoadHandler(prevState => ({
                    ...prevState,
                    allDeceased: false
                }));
            }
        });
    }

    function loadAllGroups(session, accID){
        let existingGroups = session?.account?.data?.[accID]?.existingGroups;
        if(existingGroups){
            session?.individual?.functions?.importGeneratedList(existingGroups, "internal_group", "individuals", session);
    
            setLoadHandler(prevState => ({
                ...prevState,
                allGroups: true
            }));
        }
    }

    function resetLoaders(session){
        setLoadHandler({
            policyTrackline : false,
            policyData : false,
            notificationData : false,
            reportingData : false,
            allDeceased : false,
            allGroups : false,
        });

        session?.notifications?.functions?.loadNotifications(undefined);
    }

    const initialize = {
        loadHandler,
        setLoadHandler,
        loadNotificationData,
        loadReportingData,
        loadDeceased,
        loadAllGroups,
        resetLoaders
    }

  return initialize;
};

export default Initialization;