import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PublicIcon from '@mui/icons-material/Public';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import SaveIcon from '@mui/icons-material/Save';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PreviewIcon from '@mui/icons-material/Preview';
import ListIcon from '@mui/icons-material/List';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

import isEqual from 'lodash/isEqual';

import { CircularProgress } from '@mui/material';
import ReportSheetEditableCell from './ReportSheetEditableCell.js';
import ReportActionBar from './ReportActionBar.js';
import ReportProfile from './ReportProfile.js';
import EditMode from './EditMode.js';
import ResultsRenderHandler from './SummaryReport/ResultsRenderHandler.js';
import PrintingFunctions from './Functions/PrintingFunctions.js';

function ReportSheet(props){
    const session = props?.session;

    const dataPointerStems = [
        "deathTrac",
        "lifeTrac",
    ]
    const [floater, setFloater] = useState({
        top: false,
        bottom: false,
    });

    const [listResults, setListResults] = useState([]);

    const reportTable = useRef(null);
    const columnContainerRef = useRef(null);
    const rowRefs = useRef({});
    const lastScrollTop = useRef(0);

    const [stickyColumns, setStickyColumns] = useState({});
    const [columnWidth, setColumnWidth] = useState(null);
    const [isScrollableX, setIsScrollableX] = useState(false);

    const [profileLoading, setProfileLoading] = useState(false);

    const cachedFormattedDates = new Map();
    const printingFunctions = PrintingFunctions({session, cachedFormattedDates})?.functions;
    const search = props?.search?.data;

    const importedList = props?.importedList;
    const edit = session?.edit;

    const viewport = session?.env?.viewport;
    const viewType = viewport?.viewType;
    const envFunctions = props.session.env.functions;
    const reporting = session?.reporting;
    const dashboard = session?.dashboard;
    const currentAccountID = session?.handler?.data?.currentAccountID;
    const selectionState = reporting?.data[currentAccountID]?.selectionState;
    const selectedReport = reporting?.data[currentAccountID]?.selectedReport; // Add edit
    const reportType = reporting?.functions?.reportType(selectedReport?.referenceStem ?? selectedReport?.stem);
    const updateReporting = props?.remoteReport ? dashboard?.setData : reporting?.setData;

    const hasBeenGenerated = session?.[reporting?.data[currentAccountID]?.dataPointer]?.data[currentAccountID]?.[selectedReport?.referenceBranch ?? selectedReport?.branch]?.[selectedReport?.referenceStem ?? selectedReport?.stem]?.[0];
    const selectedReportStem = selectedReport?.stem;
    const userOpportunityTypes = session?.user?.data?.opportunityTypes;
    const attributeData = session?.[reporting?.data[currentAccountID]?.dataPointer]?.data[currentAccountID]?.attributeData;

    const currentStructure = props.importedList ? importedList : selectedReport;
    const viewableReport = props?.importedList ? true : (currentStructure?.query && currentStructure?.columns?.length > 0);

    const rowErrors = session?.edit?.data?.rowErrors;
    const selectableRows = (currentStructure !== importedList) || !selectedReport?.edit

    const editingCell = session?.edit?.data?.editHandler?.position;
    const validateEdit = session?.edit?.functions?.validateEdit;
    const displayErrors = session?.edit?.data?.displayErrors;
    const filterErrors = session?.edit?.data?.filterErrors;

    const updateEdit = session?.edit?.functions?.updateReport;

    const currentPath = importedList ? "edit" : "reporting";
    const currentVars = importedList ? edit : reporting;
    const currentFunction = importedList ? "updateImportedList" : "updateSelectedReport";
    const currentSetData = importedList ? updateEdit : updateReporting;
    const currentSorting = importedList ? edit?.data?.sorting : reporting?.data[currentAccountID]?.sorting;
    const selectedDropdown = props?.selectedDropdown?.data;
    const setSelectedDropdown = props?.selectedDropdown?.set;

    const [currentIndex, setCurrentIndex] = useState(null);
    const [reportSortedData, setReportSortedData] = useState(null);
    const [individualPageChange, setIndividualPageChange] = useState(false);
    const [prevPageChange, setPrevPageChange] = useState(false);

    const sortedData = { list: undefined };

    const [currentView, setCurrentView] = useState("individual");
    
    // const [existingColumnsCopy, setExistingColumnsCopy] = useState(currentStructure?.columns);
    const [tempReportReference, setTempReportReference] = useState(currentStructure);

    const [eligibleStickyColumns, setEligibleStickyColumns] = useState(setupStickyColumnsExclusion());

    function currentValidColumns(columns) {
        const specialColumnsEligible = selectedReport?.subReport?.name || selectedReport?.stem !== "summary";
        const currentStem = selectedReport?.subReport?.name ? selectedReport?.referenceStem : selectedReport?.stem;
        const hasFiles = reporting?.data[currentAccountID]?.reportDataHandler?.[currentStem]?.folderName;
    
        return Object.entries(columns)
            .filter(([, column]) => 
                !column?.placeholder &&
                (specialColumnsEligible || !column?.special) &&
                (column?.key !== "select" || hasFiles)
            )
            .map(([, column]) => column);
    }

    function generateGridTemplateColumns() {
        const specialColumnsEligible = selectedReport?.subReport?.name || selectedReport?.stem !== "summary";
        const columnCount = specialColumnsEligible && allColumns("count");
        const defaultCols = columnCount > 0 ? `repeat(${columnCount}, 50px) ` : '';

        const additionalCols = `repeat(${
            currentValidColumns(currentStructure?.subReport?.columns || currentStructure?.columns || [])?.length || 0
        }, 1fr)`;
        return defaultCols + additionalCols;
    }

    const columnStructure = (row) => {
        return {
            gridTemplateColumns: generateGridTemplateColumns(),
            zIndex : row ? 1 : (allColumns())?.length + 1 || 0,
        };
    };

    const [infiniteScrollMax, setInfiniteScrollMax] = useState(100);
    const pagination = props?.pagination?.data;
    const setPagination = props?.pagination?.set;

    function calculateColumnWidth(){
        const measureColumnWidth = () => {
            if (columnContainerRef.current) {
                let columnWidthInPixels;
                const gridColumnGap = parseFloat(getComputedStyle(columnContainerRef.current)?.gridColumnGap) || 0;

                const gridContainerWidth = columnContainerRef.current.clientWidth;
                const numActualColumns = currentStructure?.columns?.length || 0 // Number of actual columns
                const numSpecialColumns = allColumns("count"); // Number of special columns

                // Calculate the total width occupied by special columns without the additional 16 pixels
                const totalSpecialColumnsWidth = numSpecialColumns * 50; // 50px for each special column

                // Calculate the total width available for regular columns, taking into account the padding for these columns only
                const availableWidthForRegularColumns = gridContainerWidth - totalSpecialColumnsWidth - (numActualColumns * 16);
                
                // Calculate the width of each regular column, including padding and adjusting for the gaps between columns
                if (numActualColumns > 0) {
                    // Calculate the width of each regular column, including padding and adjusting for the gaps between columns
                    columnWidthInPixels = (availableWidthForRegularColumns + gridColumnGap * (numActualColumns - 1)) / numActualColumns;
                } else {
                    // Handle the case where numActualColumns is 0
                    columnWidthInPixels = currentStructure?.columns?.length; // or a default value that makes sense for your layout
                }
                setColumnWidth(columnWidthInPixels + 16);
            }
        };
        
        // Initial measurement
        measureColumnWidth();
      
        // Recalculate when the window is resized
        window.addEventListener('resize', measureColumnWidth);
      
          // Cleanup
        return () => {
            window.removeEventListener('resize', measureColumnWidth);
        };
    }

    function allColumns(returnType) {
        const baseConfig = {
            frozen: true,
            special: true,
        };
    
        const columnDetails = [
            { 
                key: 'rowIndex',
                columnName: 'rowIndex',
                friendlyTerm: 'Row',
                icon: <ListIcon/> },
            {
                key: 'select',
                columnName: 'selectBubble',
                friendlyTerm: 'Select',
                icon: <HighlightAltIcon/>,
                onClick: () => reporting?.functions?.toggleAllSelectRows(selectedReport?.sortedListResults, selectedReport?.stem)
            },
            {
                key: 'view',
                columnName: 'view',
                friendlyTerm: 'View',
                icon: <PreviewIcon/>
            },
        ];
    
        const specialColumnsConfig = columnDetails.map(({ key, ...rest }) => ({
            ...baseConfig,
            id: `_${key}`,
            key,
            ...rest,
        }));
    
        const specialColumns =  currentValidColumns(specialColumnsConfig.filter(({ key }) => importedList ? edit?.data?.specialColumns?.[key] : reporting?.data[currentAccountID]?.specialColumns?.[key]));
        // const specialColumns =  specialColumnsConfig.filter(({ key }) => currentVars?.data?.specialColumns?.[key]);
        return returnType === 'count' ? 
            specialColumns?.length : 
            [...specialColumns, ...( currentStructure?.columns ?? [])];
    }

    function printColumnHeaders(){
        let stickyCount = 0;
        let columnHeaders = [];
        let columnCount = currentValidColumns(allColumns())?.length || 0;

        if(currentStructure?.columns?.length > 0){
            const sorting = currentStructure === selectedReport ? reporting?.data[currentAccountID]?.sorting : edit?.data?.sorting;
            const shouldProceed = selectedReport?.stem === "summary"
            ? selectedReport?.columns.filter(column => !column.hasOwnProperty('custom')).length > 1
            : true;

            currentValidColumns(allColumns())?.forEach((column, index) => {
                const frozen = column?.frozen;
                const columnPointer = column?.fieldBy ?? column?.columnName;
                const currentIndex = sorting?.findIndex(obj => obj.columnName === columnPointer);
                const ascending = sorting?.[currentIndex]?.type === "asc";
                const descending = sorting?.[currentIndex]?.type === "desc";

                if (frozen){
                    stickyCount++;
                    columnCount--;
                }

                columnHeaders.push(
                    <div
                        key={index}
                        className={`columnBlock cC g fR${column?.onClick ?
                            " p" : ''}${frozen && isScrollableX && !column?.special ?
                                " frozen" : ''}${column?.altColor ?
                                    " alt" : ''}${column?.special ?
                                        " special frozen" : ''}${
                                            frozen && stickyCount === 1 ? " first" : ''}`
                        }
                        style={stickyPosition(stickyCount, frozen, column?.special)}
                        onClick={column?.onClick ? column?.onClick : null}
                    >
                        {isScrollableX && !column?.special && !(selectedReport?.stem === "summary" && !selectedReport?.subReport?.name) &&
                            <div
                                className="f cC p setFrozen"
                                onClick={()=>{
                                    currentStructure == selectedReport ? 
                                    updateReporting(`${props?.remotePath ?? session?.handler?.data?.currentAccountID}.selectedReport.columns.` + (index - allColumns("count")), "frozen", !frozen) 
                                    :
                                    updateEdit("importedList.columns." + (index - allColumns("count")), "frozen", !frozen);
                                }}
                            >
                                <AcUnitIcon/>
                            </div>
                        }
                        <div
                            className={`${column?.friendlyTerm ? "bold " : "italics "}f cC${
                                !column?.special ? " gCW gC2" : " cC lH fR g"}`}
                            title={column?.title ?? column?.friendlyTerm ?? "*Unnamed Column"}
                        >
                            {column?.special ? column?.icon : (viewType === "mobile" && column?.mobileFriendlyTerm) || column?.friendlyTerm || "*Unnamed Column"}
                        </div>
                        {!column?.special && !column?.summarizeBy && shouldProceed &&
                            <div className={`sort f g p gC3`}>
                                {['asc', 'desc'].map((sortType) => {
                                    const isActive = sortType === 'asc' ? ascending : descending;
                                    const IconComponent = sortType === 'asc' ? ArrowDropUpIcon : ArrowDropDownIcon;
                                    return (
                                        <div
                                            key={sortType}
                                            className={`icon${isActive ? " active" : ''}`}
                                            onClick={() => {

                                                // Find if the current column is already in the sorting array
                                                const existingIndex = sorting.findIndex(s => s.columnName === columnPointer);
                                                let newSorting;
                                                if (existingIndex !== -1) {
                                                    if (sorting[existingIndex].type === sortType) {
                                                        // If the current column is already in the sorting array with the same type, remove it
                                                        newSorting = sorting.filter((_, index) => index !== existingIndex);
                                                    } else {
                                                        // If the current column is in the sorting array with a different type, update the type
                                                        newSorting = [...sorting];
                                                        newSorting[existingIndex] = { ...newSorting?.[existingIndex], type: sortType };
                                                    }
                                                } else {
                                                    // If the current column is not in the sorting array, add it
                                                    newSorting = [...sorting, { type: sortType, columnName: column?.columnName }];
                                                }

                                                // Update the current sorting state
                                                importedList ? updateEdit('sorting', newSorting) : updateReporting(`${props?.remotePath ?? session?.handler?.data?.currentAccountID}`, 'sorting', newSorting)

                                                // Make the request if needed
                                                if (reportType === "generated" && !props.importedList) {
                                                    reporting?.functions?.requestResultBlock(
                                                        { newSorting },
                                                        props?.requestObject,
                                                        session?.handler?.data?.currentAccountID
                                                    );
                                                }
                                            }}
                                        >
                                            <IconComponent />
                                        </div>
                                    );
                                })}
                            </div>
                        }
                    </div>
                );
            });
        }

        return (
            columnHeaders?.length > 0 ?
                columnHeaders
            :
                <div className="f cC">
                    No columns selected
                </div>
        );
    }

    const stickyPosition = (stickyCount, frozen, special) => {
        if (!frozen) return;
        const specialColumnsCount = allColumns("count");
        const offset = special ? 50 : columnWidth;
        const position = special ? (stickyCount - 1) * offset : specialColumnsCount * 50 + (stickyCount - 1 - specialColumnsCount) * columnWidth;
    
        return { left: `${position + 2}px`, zIndex: allColumns()?.length || 0 };
    };

    function printListResults(call){
        let rowCount = 0;
        let filterCount = 0;
        let stickyCount = 0;
        let columnCount = allColumns()?.length || 0;

        let results = [];
        const searchData = search?.searchValue?.toLowerCase()?.split(' ');

        const memoize = (func) => {
            const cache = new Map();
            return (...args) => {
              const key = JSON.stringify(args);
              if (cache.has(key)) {
                return cache.get(key);
              }
              const result = func(...args);
              cache.set(key, result);
              return result;
            };
        };

        const memoizedRenderFormattedColumnValue = memoize(printingFunctions?.renderFormattedColumnValue);

        if (!currentStructure || !currentStructure?.columns || !currentStructure?.list) {
            setListResults([]);
            return null; // Return null or some indication of an issue
        }
        const sortList = (a, b, currentSorting) => {
            for (const sortCriteria of currentSorting) {
                const { columnName, type } = sortCriteria;
                const valueA = a[columnName];
                const valueB = b[columnName];

                // Convert string representations of numbers to actual numbers
                const parsedValueA = isNaN(valueA) ? valueA : parseFloat(valueA);
                const parsedValueB = isNaN(valueB) ? valueB : parseFloat(valueB);

                // Handle null, undefined, or non-numeric values
                if (parsedValueA === null && parsedValueB !== null) {
                    return type === 'asc' ? 1 : -1;
                }

                if (parsedValueA !== null && parsedValueB === null) {
                    return type === 'asc' ? -1 : 1;
                }

                if (typeof parsedValueA === 'undefined' && typeof parsedValueB === 'undefined') {
                    continue;
                }

                if (typeof parsedValueA === 'undefined' || parsedValueA === null) {
                    return type === 'asc' ? 1 : -1;
                }

                if (typeof parsedValueB === 'undefined' || parsedValueB === null) {
                    return type === 'asc' ? -1 : 1;
                }

                // Compare numeric values
                if (!isNaN(parsedValueA) && !isNaN(parsedValueB)) {
                    // Compare floating-point numbers
                    if (parsedValueA !== parsedValueB) {
                        return type === 'asc' ? parsedValueA - parsedValueB : parsedValueB - parsedValueA; // Reverse the order for descending sorting
                    }
                } else {
                    // Compare string values
                    if (typeof parsedValueA === 'string' && typeof parsedValueB === 'string') {
                        if (parsedValueA !== parsedValueB) {
                            return type === 'asc' ? parsedValueA.localeCompare(parsedValueB) : parsedValueB.localeCompare(parsedValueA);
                        }
                    } else {
                        // If not a string or number, use default string comparison
                        const stringValueA = String(parsedValueA);
                        const stringValueB = String(parsedValueB);
                        if (stringValueA !== stringValueB) {
                            return type === 'asc' ? stringValueA.localeCompare(stringValueB) : stringValueB.localeCompare(stringValueA);
                        }
                    }
                }
            }
            return 0;
        };

        const primaryList = selectedReport?.subReport?.list || currentStructure?.list;
        sortedData.list = primaryList?.map((item, index) => ({...item, _key: index})).sort((a, b) => sortList(a, b, currentSorting));

        setReportSortedData(sortedData);
        if (search?.searchValue && !props?.importedList) {
            const searchTerms = search?.searchValue?.toLowerCase()?.split(' ');

            sortedData.list = sortedData?.list?.filter((rowObj) => {
                return searchTerms?.every((term) => {
                    return allColumns()?.some((column) => {
                        const attr = column?.columnName;
                        const columnValueLower = rowObj?.[attr]?.toString()?.toLowerCase();

                        // Check if the rowObj's attribute value contains a space
                        if (columnValueLower?.includes(' ')) {
                            // Split the attribute value into words
                            const attributeWords = columnValueLower?.split(' ');

                            // Check if any of the words match the search term
                            return attributeWords?.some((word) => word?.includes(term));
                        } else if(selectionState?.selectedRows?.some(row => row.recordID === rowObj?.[reporting?.data[currentAccountID]?.selectAllVar])) {
                            return true;
                        } else {
                            // Treat it as a normal match if there's no space
                            return memoizedRenderFormattedColumnValue(columnValueLower, attributeData?.[attr]?.returnType, "stringOnly")?.toString()?.includes(term);
                        }
                    });
                });
            });
        }

        // if(!props?.remoteReport){
        //     updateReporting(`${props?.remotePath ?? session?.handler?.data?.currentAccountID}.selectedReport`, "sortedListResults", sortedData?.list);
        // }
        
        // props?.sortedListResults?.set(sortedData?.list);
        if (currentStructure !== importedList && selectedReport?.stem === "summary" && !selectedReport?.subReport?.list) {
            const resultsHandler = ResultsRenderHandler({
                session,
                sortedData,
                attributeData,
                cachedFormattedDates,
                renderFormattedColumnValue : printingFunctions?.renderFormattedColumnValue,
                searchData,
                updateReporting,
                allColumns : currentValidColumns(allColumns()),
                rowRefs : rowRefs.current,
                remotePath : props?.remotePath,
                selectedReport,
            })?.functions;
        
            const currFunction = selectedReport?.details?.displayType === "dynamic" ? "generateBlocks" : "generateRows";
        
            results = resultsHandler?.[currFunction]?.();
        
            // Check if any sticky column has data
            const hasStickyData = currentValidColumns(allColumns()).some(column => {
                const handler = stickyColumns?.[column?.id];
                return handler && Object.keys(handler)?.length > 0;
            });

            const stickyStyle = currentStructure?.columns?.length ? columnStructure() : { zIndex: (allColumns())?.length || 0 + 1 };
            stickyStyle.visibility = hasStickyData ? 'visible' : 'hidden'; // Add visibility to the existing style
            stickyStyle.display = hasStickyData ? 'grid' : 'contents';

            const stickyColumnsDisplay = (
                <div
                    key="stickyColumnsDisplay"
                    className={`stickyColumnsDisplay`}
                    style={stickyStyle}
                >
                    {currentValidColumns(allColumns()).map((column, index) => {
                        const handler = stickyColumns?.[column?.id];
                        return handler && Object.keys(handler)?.length > 0 ? (
                            <div key={column?.id} style={{ gridColumn: index + 1 }} className="dP">
                                <div
                                    className="groupByItem f cC p g bold bR"
                                    onClick={()=>{
                                        const subReport = {...selectedReport?.subReport};
                                        subReport.list = handler?.["rawList" ?? "list"];
                                        subReport.columns = reporting?.data[currentAccountID]?.defaultColumns?.[selectedReport?.referenceStem]?.detailedArray;
                                        updateReporting(`${props?.remotePath ?? session?.handler?.data?.currentAccountID}.selectedReport`, "subReport", subReport);
                                    }}
                                >
                                    <span className="f gCW s e">
                                        {handler?.value}
                                    </span>
                                </div>
                            </div>
                        ) : null;
                    })}
                </div>
            );
            // Always combine the sticky columns with results
            const displayWithResults = [stickyColumnsDisplay, ...results];
            results = displayWithResults;
        }else{
            for (const [index, rowObj] of sortedData?.list.entries()) {
                if(currentStructure === importedList && filterErrors){
                    if(
                        (
                            currentStructure?.details?.scrollType === "infinite" && 
                            filterCount > infiniteScrollMax
                        ) ||
                        (
                            currentStructure?.details?.scrollType === "pagination" &&
                            index >= ( reportType === "generated" ? true : (pagination.index - 1) * pagination?.rowMax ?? 0 ) &&
                            filterCount >= pagination?.rowMax
                        )
                    ){
                        break;
                    }
                } else {
                    if(
                        (
                            currentStructure?.details?.scrollType === "infinite" && rowCount > infiniteScrollMax
                        ) ||
                        (
                            currentStructure?.details?.scrollType === "pagination" &&
                            index >= ( reportType === "generated" ? true : (pagination?.index - 1) * pagination?.rowMax ?? 0 ) &&
                            rowCount >= pagination?.rowMax
                        )
                    ){
                        break;
                    }
                }

                const path = reporting?.functions?.formulatePath(rowObj, selectedReport);
                const commonProps = {
                    key: index,
                    className: `row f cC fR g${selectableRows && (!props?.editMode && !props?.importedList) ? " p" : ''}${props?.editMode ? " editing" : ''}${((currentIndex === index) && reporting?.data[currentAccountID]?.profileOpen) ? " reportSheetRowSelected" : ''}`,
                    style: columnStructure(true),
                    onClick: () => {
                        if (!props?.editMode && reporting?.data[currentAccountID]?.reportDataHandler?.[selectedReport?.stem]?.onClick) {
                            reporting.data[currentAccountID].reportDataHandler[selectedReport.stem].onClick(session, rowObj, currentStructure, importedList);
                            // updateReporting(`${session?.handler?.data?.currentAccountID}`, 'profileOpen', true);
                            // setProfileLoading(true);
                            updateReporting(`${session?.handler?.data?.currentAccountID}`, 'selectedRowIndex', index);
                        }
                    }
                };

                const printRow = () => {
                    const rowCells = currentValidColumns(allColumns()).map((column, colIndex) => {
                        const frozen = column?.frozen;
                        if(colIndex === 0){
                            stickyCount = 0;
                            columnCount = currentValidColumns(allColumns()).length;
                        }

                        if (frozen){
                            stickyCount++;
                            columnCount--;
                        }
                        
                        let columnValue = rowObj?.[column?.columnName];

                        // Convert date to Human readable date
                        if(currentStructure === importedList){
                            if ((column?.columnName === 'dob' || column?.columnName === 'benef_dob')){
                                //columnValue = session?.edit?.functions?.excelSerialDateToDate(columnValue).toLocaleDateString();                                                          
                            }else{
                                // If value for header is null, print '-'
                                if (columnValue == null) {
                                    columnValue = '-';
                                }
                            } 
                        }

                        // colIndex - 1 to account for rowNum column
                        const isCellError = rowErrors?.some(error => error.row === rowObj?._key && error.column === colIndex);
                        //const isCellError = rowErrorKeys?.some(error => error.row === index && error.column === colIndex);

                        const editingCellIndex = editingCell?.[index.toString()];

                        let isEditing = false;
                        // Set isEditing
                        if ((editingCell !== undefined || null) && (editingCellIndex !== undefined || null) && (Object?.keys(editingCell) !== undefined || null) && (Object?.keys(editingCellIndex) !== undefined || null)){
                            if(Object?.keys(editingCell)?.includes(index?.toString()) && Object?.keys(editingCellIndex)?.includes(colIndex?.toString())){
                                let rowPos = Object?.keys(editingCell)?.indexOf(index?.toString());
                                let colPos = Object?.keys(editingCellIndex)?.indexOf(colIndex?.toString());
                                isEditing = Object?.keys(editingCell)?.[rowPos] == index && Object?.keys(editingCellIndex)?.[colPos] == colIndex;
                            }     
                        }else if((selectedReport?.editable && column?.editable)){
                            isEditing = true;
                        }

                        // Check if value is valid
                        let validEdit = null;
                        if (isCellError){        
                            validEdit = validateEdit(columnValue, column?.columnName);    
                        }

                        // ROW CELL

                        if(column?.special){
                            return (
                                <div
                                    key={index + "-" + colIndex}
                                    className={`cell frozen special g cC zT f g fC fR${attributeData?.[column?.columnName]?.altColor ? " alt" : ''}${frozen && isScrollableX ? " frozen" : ''}${frozen && stickyCount === 1 ? " first" : ''}`}
                                    style={stickyPosition(stickyCount, frozen, column?.special)}
                                    onClick={(e)=>{
                                        if(props?.editMode){
                                            e?.stopPropagation();
                                        }
                                    }}
                                >
                                    {column?.columnName === "rowIndex" &&
                                        index + 1
                                    }
                                    {column?.columnName === "selectBubble" &&
                                        (
                                            selectionState?.selectedRows?.some(row => row.recordID === rowObj?.[reporting?.data[currentAccountID]?.selectAllVar]) ?
                                                <CheckBoxIcon
                                                    className="p"
                                                    onClick={(e)=>{
                                                        reporting?.functions?.selectRowHandler(
                                                            index,
                                                            rowObj?.[reporting?.data[currentAccountID]?.selectAllVar],
                                                            sortedData?.list,
                                                            selectedReport?.stem
                                                        )(e)
                                                    }}
                                                />
                                            :
                                                <CheckBoxOutlineBlankIcon
                                                    className="p"
                                                    onClick={(e)=>{
                                                        reporting?.functions?.selectRowHandler(
                                                        index,
                                                        rowObj?.[reporting?.data[currentAccountID]?.selectAllVar],
                                                        sortedData?.list,
                                                        selectedReport?.stem
                                                        )(e);
                                                    }}
                                                />
                                        )
                                    }
                                    {column?.columnName === "view" &&
                                        <Link
                                            // to={path}
                                            className="cellLink"
                                        >
                                            <VisibilityIcon
                                                className="p"
                                            />
                                        </Link>
                                    }
                                </div>
                            )
                        }
                        
                        return (
                            (currentStructure === importedList) ?
                                <div
                                    id={"scroll-"+index+ "-" + colIndex}
                                    key={`${index}-${colIndex}Invalid`}
                                    className={`f ${
                                        attributeData?.[column?.columnName]?.altColor ? " alt" : ''}${
                                            frozen && isScrollableX ? " frozen" : ''}`
                                    }
                                    style={stickyPosition(stickyCount, frozen, column?.special)}
                                >
                                    <ReportSheetEditableCell
                                        session={session}
                                        index={index}
                                        rowKey={rowObj?._key}
                                        colIndex={colIndex}
                                        validEdit={validEdit}
                                        isCellError={isCellError}
                                        isEditing={isEditing}
                                        columnValue={isEditing ? session?.edit?.data?.editHandler?.position?.[index]?.[colIndex] : columnValue}
                                        column={column}
                                        importedList={props?.importedList}
                                        memoizedRenderFormattedColumnValue = {memoizedRenderFormattedColumnValue}
                                        attributeData = {attributeData}
                                        searchData = {searchData}
                                        frozen = {frozen}
                                        stickyPosition = {stickyPosition}
                                        isScrollableX = {isScrollableX}
                                        editableReport = {currentStructure !== importedList}
                                        reset = {props?.reset}
                                        selectedDropdown={{ data : selectedDropdown, set : setSelectedDropdown}}
                                        editCellDropdownHandler={props?.editCellDropdownHandler}
                                    />
                                </div>
                            :
                                <div
                                    key={index + "-" + colIndex}
                                    className={`cell f cC zT g fC fR${attributeData?.[column?.columnName]?.altColor ? " alt" : ''}${frozen && isScrollableX ? " frozen" : ''}${frozen && stickyCount === 1 ? " first" : ''}`}
                                    style={stickyPosition(stickyCount, frozen, column?.special)}
                                    onClick={()=>{
                                        if ( reporting?.data[currentAccountID]?.reportDataHandler?.[selectedReport.stem]?.onClick) {
                                            reporting.data[currentAccountID].reportDataHandler[selectedReport.stem].onClick(session, rowObj, currentStructure, importedList);
                                            updateReporting(`${session?.handler?.data?.currentAccountID}`, 'selectedRowIndex', index);
                                        }
                                    }}
                                >
                                    <div
                                        className={`gCW${["dropdown", "generatedList"].includes(attributeData?.[column?.columnName]?.formType) && props?.editMode ? " alt" : ''}`}
                                        title={typeof columnValue === 'number' ? columnValue.toLocaleString() : columnValue}
                                        >
                                        {props?.editMode && column?.editable ?
                                            <EditMode
                                                session={session}
                                                rowIndex={index}
                                                colIndex={colIndex}
                                                columnValue={columnValue}
                                                columnData={column}
                                                importedList={props?.importedList}
                                                memoizedRenderFormattedColumnValue = {memoizedRenderFormattedColumnValue}
                                                attributeData={attributeData}
                                                searchData={searchData}
                                                frozen={frozen}
                                                stickyPosition={stickyPosition}
                                                isScrollableX={isScrollableX}
                                                editableReport={currentStructure !== importedList}
                                                resetDropdowns={props?.resetDropdowns}
                                                selectedDropdown={{ data : selectedDropdown, set : setSelectedDropdown}}
                                                editCellDropdownHandler={props?.editCellDropdownHandler}
                                                editingRowValues={props?.editingRowValues}
                                                rowObj={rowObj}
                                                // sortedListResults={props?.sortedListResults}
                                            />
                                        :
                                            <span>
                                                {memoizedRenderFormattedColumnValue(
                                                    columnValue,
                                                    attributeData?.[column?.columnName]?.returnType,
                                                    searchData,
                                                    props?.importedList && isCellError ? 'excel': undefined
                                                )}
                                            </span> 
                                        }
                                    </div>
                                </div>
                        );
                    });

                    results.push(
                        path && !props?.editMode ? (
                            <Link key={index + "Path"} {...commonProps}>
                                {rowCells}
                            </Link>
                        ) : (
                            <div key={index + "Default"} {...commonProps}>
                                {rowCells}
                            </div>
                        )
                    );
                    rowCount++;
                    filterCount++;
                }

                if(currentStructure?.details?.scrollType === "infinite"){
                    if(currentStructure === importedList && displayErrors && filterErrors && filterCount < infiniteScrollMax){
                        if(rowErrors?.some(error => error.row === rowCount)){
                            printRow();
                        } else {
                            rowCount++;
                        }
                    } else if (rowCount < infiniteScrollMax){
                        printRow();
                    }
                }
                if(currentStructure?.details?.scrollType === "pagination" &&
                    index >= ( reportType === "generated" ? 0 : (pagination?.index - 1) * pagination?.rowMax ?? 0 ) &&
                    rowCount < pagination?.rowMax
                ){
                    printRow();
                }
            // Create an array to hold cells for each column
            };
        }

        setListResults(results);
        //return results;
    }

    const handleWindowScroll = () => {
        const scrollPosition = window.scrollY;
        const fullScrollableLength = document.documentElement.scrollHeight;
        const innerScrollableLength = fullScrollableLength - window.innerHeight;
        const isScrollable = fullScrollableLength > window.innerHeight;

        if (scrollPosition < (innerScrollableLength - 52) && isScrollable) {
            setFloater({
                top: false,
                bottom: true,
            });
        } else {
            setFloater({
                top: false,
                bottom: false,
            });
        }
    };

    function setupStickyColumnsExclusion() {
        const columns = allColumns();
        const ineligibleColumns = new Set();
        let foundCustom = false;
        
        columns.forEach((column, index) => {
            if (column?.custom !== undefined) {
                foundCustom = true; // Mark that a custom column has been found
                ineligibleColumns.add(column?.id);
                if (index > 0) {
                    ineligibleColumns.add(columns?.[index - 1]?.id);
                }
            }
        });
    
        // If no custom column is found, make the last column ineligible by default
        if (!foundCustom && columns.length > 0) {
            ineligibleColumns.add(columns[columns.length - 1].id);
        }
    
        return ineligibleColumns;
    }

    const handleScroll = (e) => {
        const scrollContainer = e.target;
        const currentScrollTop = scrollContainer?.scrollTop;
        const scrollDirection = currentScrollTop > lastScrollTop.current ? "down" : "up";
        lastScrollTop.current = currentScrollTop; // Update the last scroll position

        const containerRect = scrollContainer.getBoundingClientRect();
        let lastVisibleKey = null;

        const stickyColumnsHandler = allColumns().reduce((acc, column) => {
            acc[column.id] = {};
            return acc;
        }, {});

        if (currentScrollTop < 84 &&
            (selectedReport?.stem === "summary" && !selectedReport?.subReport?.name) &&
            Object.values(stickyColumns ?? {}).some(handler => Object.keys(handler).length > 0) && 
            scrollDirection === "up" || 
            (currentScrollTop === 0 && Object.keys(stickyColumns).length > 0)) { // Check if stickyColumns is not already empty
            setStickyColumns({});
            return;
        }
        

        const isNearBottom = currentStructure?.details?.scrollType === "infinite" && 
            (scrollContainer.scrollHeight - currentScrollTop - scrollContainer.clientHeight < 25);
        if (isNearBottom && infiniteScrollMax < currentStructure?.list?.length) {
            setInfiniteScrollMax(prevMax => prevMax + 100);
        }

        Object.keys(rowRefs.current).forEach(key => {
            const refEntry = rowRefs.current[key];
            const element = refEntry.ref.current; // Access the DOM node
            if (element) {
                const rect = element.getBoundingClientRect();
                const relativeTop = rect.top - containerRect.top;
                if (relativeTop < 72) { // Check your threshold here
                    lastVisibleKey = key;
                    const columnId = refEntry.data.id; // Use the stored data
                    if (!eligibleStickyColumns.has(columnId)) {
                        stickyColumnsHandler[columnId] = {
                            ...refEntry.data, // Spread in additional data as needed
                            value: refEntry.data.rangeInfo.range
                        };
                    }
                }
            }
        });

        if (lastVisibleKey && !reporting?.functions?.isEqualWithoutAttributes(stickyColumnsHandler, stickyColumns, [])) {
            setStickyColumns(stickyColumnsHandler);
        }
    };

    const maintainScrollStatus = () =>{
        const checkScrollable = () => {
            const el = reportTable.current;
            if (el) {
                const scrollable = el.scrollWidth > el.clientWidth;
                setIsScrollableX(scrollable);
            }
        };
    
        checkScrollable();
        // Optionally, add a resize event listener if the container's size might change
        window.addEventListener('resize', checkScrollable);
    
        return () => window.removeEventListener('resize', checkScrollable);
    }

    function useDeepCompareEffect(callback, dependencies) {
        const currentDependenciesRef = useRef();

        if (!isEqual(currentDependenciesRef.current, dependencies)) {
            currentDependenciesRef.current = dependencies;
        }

        useEffect(callback, [currentDependenciesRef.current]);
    }

    const columnsDependency = JSON.stringify(selectedReport?.columns);

    useDeepCompareEffect(() => {
        if (!session?.env?.isTyping && !session?.env?.isDragging) {
          calculateColumnWidth();
          printListResults(4);
        }

        maintainScrollStatus();
      }, [
        currentStructure,
        infiniteScrollMax,
        currentStructure?.list,
        currentStructure?.criteria?.groupBy,
        stickyColumns,
        reporting?.data[currentAccountID]?.sorting,
        reporting?.data[currentAccountID]?.specialColumns,
        reporting?.data[currentAccountID]?.specialColumns?.rowIndex,
        reporting?.data[currentAccountID]?.specialColumns?.select,
        reporting?.data[currentAccountID]?.specialColumns?.view,
        selectedReport?.columns,
        columnsDependency,
        currentStructure?.query
      ]);

    useEffect(() => {
        // Handle individual selected on page change
        if(selectedReport?.stem === "individuals" && individualPageChange){
            if(individualPageChange === 3){
                reporting.data[currentAccountID].reportDataHandler[selectedReport.stem].onClick(session, reportSortedData?.list[0], currentStructure, importedList);
                setIndividualPageChange(false);
            }else{
                setIndividualPageChange(individualPageChange+1);
            }  
        }
        if(selectedReport?.stem === "individuals" && prevPageChange){
            if(prevPageChange === 3){
                updateReporting(`${session?.handler?.data?.currentAccountID}`, 'selectedRowIndex', pagination?.rowMax-1);
                reporting.data[currentAccountID].reportDataHandler[selectedReport.stem].onClick(session, reportSortedData?.list[pagination?.rowMax-1], currentStructure, importedList);
                setPrevPageChange(false);
            }else{
                setPrevPageChange(prevPageChange+1);
            }  
        }
    }, [listResults]);

    useEffect(() => {
        if(currentStructure?.list && !session?.env?.isTyping && !session?.env?.isDragging){
            printListResults(1);
            calculateColumnWidth();
            if(viewType === "mobile"){
                window.scrollTo(0, 0);
            }else{
                if(!props?.editMode && currentStructure !== importedList){
                    reportTable.current.scrollTop = 0;
                    reportTable.current.scrollLeft = 0;
                }
            }
            setInfiniteScrollMax(100);
        }
        handleWindowScroll();
    }, [
        pagination?.index,
        pagination?.rowMax,
        isScrollableX,
        props?.editingRowValues?.data?.length,
        props?.editMode,
        selectedReport?.details?.displayType,
        selectedReport?.subReport?.list,
    ]);

    useEffect(() => {
        if(currentStructure?.list && !session?.env?.isTyping && !session?.env?.isDragging){
            printListResults(2);
            calculateColumnWidth();
        }
    }, [props?.editCellDropdownHandler?.data]);

    useEffect(() => {
        if(!session?.env?.isTyping && !session?.env?.isDragging){
            printListResults(3);
        }
        calculateColumnWidth();
    }, [selectionState?.selectedRows]);

    useEffect(() => {
        printListResults();
        const newIndex = session?.reporting?.data[currentAccountID]?.selectedRowIndex;
        if (newIndex !== currentIndex) {
            setCurrentIndex(newIndex);
        }
    }, [session?.reporting?.data[currentAccountID]?.selectedRowIndex, currentIndex]);

    useEffect(() => {
        setProfileLoading(false);
    }, [reporting?.data[currentAccountID]?.selectedRowIndex]);

    useEffect(() => {
        if(reportType !== "generated" && !selectedReport?.list?.length && selectedReport?.recordID && props?.session?.[reporting?.data[currentAccountID]?.dataPointer]?.data[currentAccountID]?.[selectedReport?.referenceBranch ?? selectedReport?.branch]){
            const items = session?.[reporting?.data[currentAccountID]?.dataPointer]?.data[currentAccountID]?.[currentStructure?.referenceBranch ?? currentStructure?.branch]?.[currentStructure?.referenceStem ?? currentStructure?.stem];
            const filteredResults = reporting?.functions?.filterItemsWithQuery(currentStructure?.query, items);
            updateReporting(`${props?.remotePath ?? session?.handler?.data?.currentAccountID}.selectedReport`, "list", filteredResults)
        }
        printListResults(6);
    }, [props?.session?.[reporting?.data[currentAccountID]?.dataPointer]?.data[currentAccountID]]);

    useEffect(() => {
        if(pagination){
            setPagination((prev) => ({
                ...prev,
                index: 1,
            }));
        }
    }, [pagination?.rowMax]);



    useEffect(() => {
        if(
            !session?.env?.isTyping && // Ensure user has stopped typing
            currentStructure?.query &&
            reportType === "generated" 
            // tempReportReference?.query !== currentStructure?.query &&
        ){
            reporting?.functions?.requestResultBlock(
                {newOffset : 1, newLimit : pagination?.rowMax},
                props?.requestObject,
                session?.handler?.data?.currentAccountID,
            );
        }

        if(allColumns()?.length){
            setEligibleStickyColumns((setupStickyColumnsExclusion()));
        }
    }, [currentStructure?.criteria, session?.env?.isTyping, currentStructure?.query, currentStructure?.columns, session?.reporting?.data[currentAccountID].tempReport]);

    useEffect(() => {
        if(currentStructure?.query){
            if(reportType !== "generated"){
                const items = session?.[reporting?.data[currentAccountID]?.dataPointer]?.data[currentAccountID]?.[currentStructure?.referenceBranch ?? currentStructure?.branch]?.[currentStructure?.referenceStem ?? currentStructure?.stem];
                const filteredResults = reporting?.functions?.filterItemsWithQuery(currentStructure?.query, items);
                currentStructure == selectedReport ?
                updateReporting(`${props?.remotePath ?? session?.handler?.data?.currentAccountID}.selectedReport`, "list", filteredResults) :
                updateEdit("importedList", "list", filteredResults);
            }
        }

        if(currentStructure?.lastRecordID !== currentStructure?.recordID && !props?.remotePath){
            importedList ? updateEdit("sorting", []) : updateReporting(`${props?.remotePath ?? session?.handler?.data?.currentAccountID}`, 'sorting', []);
            // setExistingColumnsCopy(currentStructure?.columns);
            setTempReportReference(currentStructure);
            session?.[currentPath]?.functions?.[currentFunction]("lastPageIndex", 1, session?.handler?.data?.currentAccountID); 
            if(pagination){
                setPagination((prev) => ({
                    ...prev,
                    index: 1,
                }));
            }   
            
        }

        session?.[currentPath]?.functions?.[currentFunction]("lastRecordID", currentStructure?.recordID, session?.handler?.data?.currentAccountID);

        if (reportTable?.current) {
            reportTable.current.scrollLeft = 0;
        }
    }, [
        currentStructure?.recordID,
        ...dataPointerStems.map(key => session?.[reporting?.data[currentAccountID]?.dataPointer]?.data[currentAccountID]?.[key]),
    ]); // Use Effect difference

    useEffect(() => {
        if(pagination){
            setPagination((prev) => ({
                ...prev,
                index: 1,
            }));
        }
        printListResults(5);

    }, [search?.searchValue, session?.edit?.data]);

    useEffect(() => {
        maintainScrollStatus();
        window.addEventListener('scroll', handleWindowScroll);
    }, [], viewport?.width);

    useEffect(() => {
        printListResults();
    }, [selectedReport?.columns]);

    return (
        <div
            key="report"
            className={`report b${
                viewType !== "mobile" ? " s e" : ''} g zTPC${
                    currentStructure?.ID && listResults?.length === 0 ? " gathering" : ''}${
                        props?.editMode ? " edit" : ''}${
                            currentStructure !== importedList && selectedReport?.details?.displayType === "dynamic" && viewableReport && !selectedReport?.subReport?.list ? " blockType" : ''}${
                                currentStructure !== importedList && selectedReport?.stem === "summary" && !selectedReport?.subReport?.list ? " summary" : ''
                            }
                            ${(reporting?.data[currentAccountID]?.profileOpen && viewType !== "mobile" && currentStructure !== importedList) ? "reportProfileOpen" : "reportProfileClosed"}`}
        >  
            
            <div id='fileContainer' key="reportTable" ref={reportTable} onScroll={(e)=>{handleScroll(e)}} className={`list f gC1 g${(!viewableReport || listResults?.length === 0) ? " inactive" : ''}`}>
                <div
                     key="columnHeader"
                     className={`columnHeader cC fR g${(currentStructure?.subReport?.columns?.length ?? currentStructure?.columns?.length) > 5 && !props?.remoteReport ? " expanded" : ''}${!viewableReport ? " loading" : ''}`}
                     style={currentStructure?.columns?.length ? columnStructure() : {zIndex : (allColumns())?.length || 0 + 1}}
                    ref={columnContainerRef}
                >
                    {printColumnHeaders()}
                </div>
                {!viewableReport ?
                    <div key="unviewableReport" className={`loading empty f cC g`}>
                        Select a report or complete the following to get a new report started
                        <div className="checkList thin g">
                            <div className="g">
                                <div className="f cL">
                                    Filters
                                </div>
                                {currentStructure?.query?.length > 0 ?
                                    <CheckCircleIcon/>
                                :
                                    <HighlightOffIcon/>
                                }
                            </div>
                            <div className="g">
                                <div className="f cL">
                                    Columns
                                </div>
                                {currentStructure?.columns?.length > 0 ?
                                    <CheckCircleIcon/>
                                :
                                    <HighlightOffIcon/>
                                }
                            </div>
                        </div>
                    </div>
                :
                    <React.Fragment key="viewableReport">
                        {listResults?.length > 0 || currentStructure?.list?.length > 0 ? 
                            listResults
                        :
                            ((hasBeenGenerated || selectedReport?.totalFound === 0 || (selectedReport?.stem === 'deceased' && listResults?.length === 0)) && currentStructure !== importedList) ?
                            <div className="f cC empty loading">
                                    {search?.searchValue ? (
                                        `No rows match where a column contains "${search?.searchValue}"`
                                    ) : (
                                        "There are no results to show for provided requirements"
                                    )}
                                </div>
                            :
                                <div className="loading f cC g">
                                    <CircularProgress color="inherit"/>
                                </div>
                        }
                    </React.Fragment>
                }
            </div>
            {props?.editMode &&
                <div className="confirmationBar g f fR">
                    <div className="prompt f g cC gC2 fR">
                        <div className="f cC bold">
                            {"Editing Mode"}
                        </div>
                    </div>
                    <div className="save f g cR gC3 fR">
                        <div
                            onClick={()=>{
                                reporting?.functions?.updateRows(session, props?.editingRowValues?.data);
                            }}
                            className={`btnWIcon bold bR f dP dP cC ns p fC fR${Object?.keys(props?.editingRowValues?.data)?.length === 0 ? " inactive" : ''}`}
                        >
                            <div className="f cC lH">
                                Save
                            </div>
                            <SaveIcon/>
                        </div>
                    </div>
                </div>
            }
            {currentStructure !== importedList && !props?.remoteReport &&
                <div className='gC1 gR2'>
                    <ReportActionBar
                        session={session}
                        currentStructure={currentStructure}
                        search={{data : search}}
                        floater={{data : floater, set : setFloater}}
                        importedList={props?.importedList}
                        pagination={{data : pagination, set : setPagination}}
                        resetDropdowns={props?.resetDropdowns}
                        sortedListResults={props?.sortedListResults}
                        requestObject={props?.requestObject}
                    />
                </div>  
            }
            {(reporting?.data[currentAccountID]?.profileOpen && viewType !== "mobile" && currentStructure !== importedList) &&
                <div className='gC2 gR1 g profileContainer '>
                    <div className='gR1'>
                        <ReportProfile
                            session={session}
                            sessionUser={session?.user}
                            currentView={currentView}
                            setCurrentView={setCurrentView}
                            profileLoading={profileLoading}
                        />
                    </div>

                    <div className='gR2 f profileActionBar dP'>
                        <div className='g individualNavigation'>
                            <div className='gC1 btnWIcon cC dP bR p dG'
                                onClick={()=>{
                                    if((selectedReport?.stem === "individuals" && !(currentIndex === 0 && pagination?.index === 1)) || (selectedReport?.stem !== "individuals" && currentIndex !== 0 && currentIndex !== null)){
                                        
                                        if( (selectedReport?.stem === "individuals" && currentIndex === 0 && pagination?.index !== 1) ||
                                            ((selectedReport?.stem !== "individuals") && (currentIndex !== 0) && (((reporting?.data[currentAccountID]?.selectedRowIndex + 1) % pagination?.rowMax) === 1)) 
                                            ){
                                            setPagination((prev) => ({
                                                ...prev,
                                                index: pagination?.index - 1,
                                            }));
                                            if(selectedReport?.stem === "individuals"){
                                                setPrevPageChange(1);
                                                let newOffset = pagination.index - 1;
                                                reporting?.functions?.requestResultBlock({newOffset : newOffset}, props?.requestObject, session?.handler?.data?.currentAccountID);
                                            }
                                        }else if(selectedReport?.stem === "individuals"){
                                            reporting.data[currentAccountID].reportDataHandler[selectedReport.stem].onClick(session, reportSortedData?.list[currentIndex-1], currentStructure, importedList);
                                        }

                                        updateReporting(`${session?.handler?.data?.currentAccountID}`, 'selectedRowIndex', currentIndex - 1);  
                                        if(selectedReport?.stem !== "individuals"){
                                            reporting.data[currentAccountID].reportDataHandler[selectedReport.stem].onClick(session, reportSortedData?.list[currentIndex-1], currentStructure, importedList);
                                        }
                                  
                                    }
                                }}>
                                <ArrowCircleLeftOutlinedIcon fontSize='small'/>
                                Prev
                            </div>

                            <div
                                className='gC5 btnWIcon cC dP bR p dG'
                                onClick={()=>{
                                    if((selectedReport?.stem === "individuals" && ((currentIndex + 1) < selectedReport?.totalFound)) || (selectedReport?.stem !== "individuals" && ((currentIndex + 1) < selectedReport?.list?.length))){
                                        
                                        updateReporting(`${session?.handler?.data?.currentAccountID}`, 'selectedRowIndex', currentIndex + 1); 
                                        if(selectedReport?.stem !== "individuals"){
                                            reporting.data[currentAccountID].reportDataHandler[selectedReport.stem].onClick(session, reportSortedData?.list[currentIndex+1], currentStructure, importedList);
                                        }
                                        
                                        if(
                                            ((selectedReport?.stem === "individuals" && reporting?.data[currentAccountID]?.selectedRowIndex !== selectedReport?.totalFound) ||
                                            (selectedReport?.stem !== "individuals" && reporting?.data[currentAccountID]?.selectedRowIndex !== selectedReport?.list?.length)) &&
                                            (((reporting?.data[currentAccountID]?.selectedRowIndex ) % pagination?.rowMax) === 0)
                                        )
                                        {   
                                            setPagination((prev) => ({
                                                ...prev,
                                                index: pagination?.index + 1,
                                            }));
                                            if(selectedReport?.stem === "individuals"){
                                                setIndividualPageChange(1);
                                                let newOffset = pagination.index + 1;
                                                reporting?.functions?.requestResultBlock({newOffset : newOffset}, props?.requestObject, session?.handler?.data?.currentAccountID);
                                                updateReporting(`${session?.handler?.data?.currentAccountID}`, 'selectedRowIndex', 0);
                                            }
                                        }
                                        else if(selectedReport?.stem === "individuals"){
                                            reporting.data[currentAccountID].reportDataHandler[selectedReport.stem].onClick(session, reportSortedData?.list[currentIndex+1], currentStructure, importedList);
                                        }
                                    }
                                }}
                            >
                                Next
                                <ArrowCircleRightOutlinedIcon fontSize='small'/>
                            </div> 
                        </div>  
                    </div>
                </div>
            }
            
        </div>
    )
}

export default ReportSheet;