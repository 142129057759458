import React, { useEffect, useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';

function UserAgreementOverlay(props){

    const session = props?.session;

    const viewType = session?.env?.viewport?.viewType;

    const [loadingStatus, setLoadingStatus] = useState(false);

    const fileRows = session?.edit?.data?.importedList.list;
    const errorCountData = session?.upload?.data?.errorCountData;

    const removeFile = () => {
        // setFiles([]);
        // setHeaders([]);
        session?.upload?.setData(prevData => ({
            ...prevData,
            removeFile: true
        }));  
        session?.edit?.functions?.updateImportedList("columns", []);
        session?.edit?.functions?.updateReport("sorting", null, []);
        session?.upload?.functions?.updateReport("errorCountData", session?.upload?.data?.initialErrorCount);
    }

    function printOverlayContent() {
        return (
            <div className='cC f dP g dG' style={{maxWidth:'750px'}}>
            {!loadingStatus ?
                <>
                    <div className='l dP'>
                        <input
                            type="checkbox"
                            style={{ transform: 'scale(1.5)' }}
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                        />
                        &emsp;Client shall securely upload to ABL Tech in an excel file (or other file type provided by ABL Tech)
                        that includes the following information for each Person: social security number, last name, first name, middle initial, 
                        spouse name, beneficiary name(s), date of birth, address, and zip code, each as available (the “Processing File”). Death 
                        Audit reports are delivered through the ABL Tech Reporting Platform or secure file transfer (“SFT”). The login and password 
                        for SFT is for a single, authorized user. Client agrees not to share login and password information among more than one user 
                        and shall promptly notify ABL Tech when an authorized user is no longer authorized to use the SFT (whether due to reassignment, 
                        termination or otherwise). To retrieve certain data to perform the Services, ABL Tech discloses Client’s Confidential Information
                        to ABL Tech's data vendor(s) and Client hereby consents to such disclosure.
                    </div>

                    <div className='userAgreementButtons g cC f'>
                        {isChecked ?
                            <div className='btnWIcon cC bold saveUploadBtn dP bR gC2' 
                                onClick={() => {
                                    session?.upload?.data?.largeUpload ? session?.edit?.functions?.editChunk(session, session?.edit?.data?.largeUploadKeys[0]) : transformHeaders(fileRows); 
                                    setLoadingStatus(true);
                                }}>
                                    Confirm Upload
                            </div>          
                            //console.log("Send Row Objects here:",fileRows);
                            :
                            <div className='cC bold saveUploadInactive saveUploadInactiveBorder dP bR gC2'>Confirm Upload</div>
                        }
                        <div className='btnWIcon cC bold saveUploadBtn dP bR gC4' onClick={() => {props?.setter(false);}}> Close</div>
                    </div> 
                </>
                
            :
                <div className="cC f dP bR" style={{ minWidth: viewType === "mobile" ? 'auto' : '750px', minHeight: '300px' }}>
                    <CircularProgress disableShrink color="success" variant="indeterminate" fontSize="large"/>
                </div>
            }
            </div>                                              
        );                                                  
    }           
        
    function transformHeaders (rowsObj){
        rowsObj.forEach(row => {
            Object.keys(row).forEach(originalHeader => {
                // Check for specific headers and apply SerializedNumToDate
                if (originalHeader === 'dob' || originalHeader === 'benef_dob') {
                    row[originalHeader] = session?.upload?.functions?.convertToISODate(row[originalHeader]);
                } 
            });
        });
    
        uploadMany();
    }

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    function uploadMany(){
        let paramVals = {
            "individuals" : fileRows,
            "uploadHistory" : errorCountData,
            "accountID" : session?.handler?.data?.currentAccountID
        };
        session?.env?.functions?.buildFetchRequest("individual/addMany", paramVals)
            .then(response => response.json())
            .then(resData => {
            setLoadingStatus(false);
            if(resData.status === 200){
                removeFile();
                session?.env?.setOverlay("uploadSuccess");
                //setFileRows([]);
                //console.log("Upload successful!")
            }else{
                session?.env?.setOverlay("uploadFailure");
                //setErrorStatus(resData.message);
            }
        });
    }

    return (
        <>
            <div className="overlayBoard g f fC oH dP">
                <div className='g'>
                    {printOverlayContent()}
                </div>
            </div>  
        </>
    );
};

export default UserAgreementOverlay;